import DateTime from "luxon/src/datetime";
import Duration from "luxon/src/duration";

export const sessionParser = (data) => {
  const locale = navigator.languages[0];

  if (data) {
    const lastDate = DateTime.fromISO(data.dateHour).setLocale(locale);
    return {
      date: {
        day: lastDate.toFormat("d"),
        month: lastDate.toFormat("LLL"),
        year: lastDate.toFormat("yyyy"),
      },
      distance: data.totalDistance || 0,
      sessionTime: data.duration || 0,
      laps: data.drillCount || 0,
      swimTime: data.swimDuration || 0,
      speed: data.pace || 0,
      restingTime: data.restingTime || 0,
    };
  }
};

export const sessionsParser = (data) => {
  return data.reduce(
    (acc, item) => [...acc, { ...sessionParser(item), moreInfo: false }],
    []
  );
};

export const totalSessionParser = (data, personalBest) => {
  // console.log(data, "datatotalSessionParser");
  return {
    distance: data.totalDistance || 0,
    laps: data.drillCount || 0,
    personalBest: personalBest.totalDistance || 0,
    sessionTime: data.duration || 0,
    swimTime: data.swimDuration || 0,
    speed: data.pace || 0,
  };
};

export const parseStat = (stat, index, locale, isTotal = false) => {
  const multiplier = locale == "en" ? 1.0936 : 1;
  const fromMillis = Duration.fromMillis(stat * 1000);

  switch (index) {
    case "distance":
    case "personalBest":
      return Math.round(parseFloat(stat) * multiplier).toLocaleString(locale);
    case "sessionTime":
    case "swimTime":
      return isTotal
        ? fromMillis.toFormat("hh:mm:ss")
        : fromMillis.toFormat("mm:ss");
    case "speed":
      return Duration.fromMillis(stat * 1000).toFormat("mm:ss");
    case "laps":
      return stat.toString();
    case "restingTime":
      return `${Math.round(stat * 10) / 10}%`;
    default:
      return "0";
  }
};

export const isEmptyForm = ({
  pwd,
  pwd2,
  comercialCheckbox,
  thirdPartiesCheckbox,
}) => {
  if (
    isEmpty(pwd) ||
    pwd == "" ||
    isEmpty(pwd2) ||
    pwd2 == "" ||
    !comercialCheckbox ||
    !thirdPartiesCheckbox
  ) {
    return true;
  }
};

export const loginErrorParser = (field, errorCode) => {
  switch (field) {
    case "email":
      if (errorCode === "invalid-email" || errorCode === "user-not-found")
        return true;
      break;
    case "pwd":
      if (errorCode === "wrong-password") return true;
      break;
    default:
      return false;
  }
};

export const isEmpty = (str) => {
  return !str.trim().length;
};

export const isDesktop = () => document.documentElement.clientWidth > 1024;

export const isObjEmpty = (obj) => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};
