// let api_url = process.env.API_URL;
let api_url = "https://api-dev.zonyx.tech/nagi/v1";
console.log(api_url, "api_url");

let HOME123 = process.env.TEMP_ALEJANDRODEV;
let HOME456 = process.env.VUE_APP_MYMOD;
let HOME789 = process.env.NODE_ENV;
let HOME789b = process.env.NODE_ENV.VUE_APP_MYMOD;
console.log(HOME123, "entorno TEMP_ALEJANDRODEV");
console.log(HOME456, "entorno VUE_APP_MYMOD");
console.log(HOME789, "entorno NODE_ENV");
console.log(HOME789b, "entorno NODE_ENV.VUE_APP_MYMOD");
console.log(process.env, "entorno");
console.log(process.env["VUE_APP_MYMODE"], "entorno");

export const baseUrl = "https://gofit.nagismartpool.com";
// export const devUrl = api_url + "/nagi/v1";
export const devUrl = api_url;
export const gatewayUrl = "https://nagismartpool.now.sh/api";
export const shhh = "zcnbbcfag35mczs73vtr8u9nku6vwejk2jmr35ezkagxvt3px4g9hughvue29rkw";
export const mockToken = "eyJhbGciOiJQQkVTMi1IUzI1NitBMTI4S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwicDJjIjo4MTkyLCJwMnMiOiJNMUdpVHl4QzZhSWpVUTdxIn0.vmV2cLvh2J0t1Hpq-qS2d4s8fA4HMw3M05a6jrpdhJFiLsrDw-1wxzUScsvcPCQOEvhJqF2claVYh_nYG8rhTu-COnSfTSXe.bcYS7WV3ANkkOchg3E2SAw.tMl5osJrPphnrwNVVkuY7c_oVNvy2cgWmBoIvQnlRhHGKz-ms0AN5l1k-AoIXiXF.NR0EtGk6BN9UpzWgXw8F3Nz19nhNq0dEqehE3R_NaKc";
