import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyC_OhLpNwDIGglLf0F8OwqLw6jyqhS2FYI",
  authDomain: "swimmergo-e0b3a.firebaseapp.com",
  databaseURL: "https://swimmergo-e0b3a.firebaseio.com",
  projectId: "swimmergo-e0b3a",
  storageBucket: "swimmergo-e0b3a.appspot.com",
  messagingSenderId: "111613861874"
};
firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;

// date issue fix according to firebase
const settings = {};
db.settings(settings);

// firebase collections
const usersCollection = db.collection("users");

export { db, auth, currentUser, usersCollection };
