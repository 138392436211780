<template>
  <div id="app">
    <div id="nav"></div>
    <router-view />
  </div>
</template>

<script>
import { store } from "./store.js";
export default {
  name: "app",
  created: function() {
    let loadBrandingNameLS = localStorage.getItem("brandingNameLS");    
    // store.dispatch("setCustomBrandingLS", loadBrandingNameLS );
    store.commit('setCustomBranding', loadBrandingNameLS);
    //store.commit('setCustomBrandingLS', loadBrandingNameLS);
    
    //commit("setCustomBrandingLS", loadBrandingNameLS);
  },
  mounted() {
    //let loadBrandingNameLS = localStorage.getItem("brandingNameLS");
    //store.commit('setCustomBrandingLS', loadBrandingNameLS);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");
#app {
  font-family: "Rubik", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  user-select: none;
  font-size: 16px;
}

.text-justify{
  text-align: justify;
}

@media screen and (max-width: 480px) {
  #page-container {
    display: block !important;
  }

}
</style>
