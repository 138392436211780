import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "*", redirect: "/" },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue")
    },
    {
      path: "/mystats",
      name: "mystats",
      component: () => import("@/views/Stats.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy",
      component: () => import("@/views/PrivacyPolicy.vue")
    },
    {
      path: "/cookies",
      name: "cookies",
      component: () => import("@/views/Cookies.vue")
    },
    {
      path: "/terms-conditions",
      name: "termsConditions",
      component: () => import("@/views/TermsConditions.vue")
    },
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;

  if (requiresAuth && !currentUser) {
    next("/");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
