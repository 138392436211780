import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "axios";

import { shhh, devUrl } from "./config";
import { sessionParser, sessionsParser, totalSessionParser } from "./parsers";

Vue.use(Vuex);

const jsonwebtoken = require("jsonwebtoken");
const fb = require("./firebaseConfig.js");

const API_URL = devUrl;

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    token: null,
    userData: {},
    user: null,
    lastSession: null,
    totalSessionsCount: 0,
    totalSessions: null,
    summary: null,
    personalBest: null,
    disableButton: false,
    summaryLoader: false,
    summaryRequest: false,
    notActiveAccount403: false,
    lastSessionLoader: false,
    performingRequest: false,
    signUpError: {},
    loginError: {},
    recoveryEmailSent: false,
    usernameError: false,
    passwordError: false,
    customBranding: {
      theBranding: "ZONYX",
      backgroundImage: "bg.jpg",
      logo: "nagiZoneLogo.png",
      bgColor: "#030d2c",
      privacyColor: "#6f8396",
    },
  },
  actions: {
    async checkUsername(_, username) {
      const snapshot = await fb.usersCollection
        .where("username", "==", username.toLowerCase())
        .get();
      return !snapshot.empty;
    },
    async getUserData({ commit }, { uid }) {
      const user = await fb.usersCollection
        .doc(uid)
        .get()
        .then((res) => res.data());
      const tokenData = {
        AuthToken: user.UserId,
        UserId: user.UserId,
        location: user.location,
        now: new Date(),
      };

      commit('getBranding', user.location);

      const token = jsonwebtoken.sign(tokenData, shhh, {
        algorithm: "HS256",
      });
      console.log(token, "Soy token getUserData");
      return commit("setUserData", {
        ...user,
        jwt: token,
      });
    },
    signIn({ commit }, { email, pwd }) {
      store.dispatch("requestLoader", true);
      fb.auth
        .signInWithEmailAndPassword(email, pwd)
        .then(async ({ user }) => {
          commit("setCurrentUser", user);

          await store.dispatch("getUserData", user);

          store.dispatch("requestLoader", false);
          router.push("/mystats");
        })
        .catch(({ code, message }) => {
          store.dispatch("requestLoader", false);
          commit("setLoginError", { code: code.split("/")[1], message });
        });
    },
    async signUp({ commit, state }, userForm) {
      if (userForm.pwd !== userForm.pwd2) {
        return commit("setPasswordError", true);
      }

      const usernameAlreadyExists = await store.dispatch(
        "checkUsername",
        state.userData.Email
      );
      if (usernameAlreadyExists) {
        return commit("setUsernameError", true);
      }

      await store.dispatch("requestLoader", true);
      // Posible data para crear el usuario cuando este le ha dado clic al enlance del email que contiene un token con la data basica de registro
      return fb.auth
        .createUserWithEmailAndPassword(state.userData.Email, userForm.pwd)
        .then(({ user }) => {
          commit("setCurrentUser", user);
          const data = {
            username: state.userData.Email,
            email: state.userData.Email,
            location: state.userData.InstallationId,
            InstallationId: state.userData.InstallationId,
            UserId: state.userData.UserId,
            //tagNumber: state.userData.UserNumber,
          };

          console.log(data,"mydata");

          return fb.usersCollection
            .doc(user.uid)
            .set(data)
            .then(async () => {
              await store.dispatch("getUserData", user);
              await store.dispatch("registerUserData", data);

              await store.dispatch("requestLoader", false);
              //router.push("/mystats");
              router.push({name: 'mystats', params: { isNewUser: true }}) //unused parameter
            })
            .catch((err) => {
              store.dispatch("requestLoader", false);
              commit("setSignUpError", err);
            });
        })
        .catch(({ message }) => {
          store.dispatch("requestLoader", false);
          commit("setEmailError", message);
        });
    },
    recoverCredentials({ commit }, email) {
      fb.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          commit("recoveryEmailSent", true);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchLastSession({ commit, state }) {
      const opts = {
        params: {
          authentication_token: state.userData.jwt,
          location: state.userData.location,
        },
      };
      return axios
        .get(`${API_URL}/session`, opts)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("setLastSession", sessionParser(data[0]));
          commit("setLastSessionLoader", false);
        })
        .catch((err) => {
          commit("setLastSessionLoader", false);
          console.log(err);
        });
    },
    fetchSummary({ commit, state }, ownOffset) {
      const opts = {
        params: {
          authentication_token: state.userData.jwt,
          location: state.userData.location,
          offset: ownOffset,
          limit: 3,
        },
      };
      return axios
        .get(`${API_URL}/sessions`, opts)
        .then(({ data }) => {
          console.log(data);
          commit("setSummaryLoader", true);
          commit("setTotalSessionsCount", data.total);
          commit("setDisableSummaryButtons", data.offset == null);
          commit("setSummary", sessionsParser(data.data));
          commit("setSummaryLoader", false);
          commit("setSummaryRequest", false);
          console.log(data, "toerror403");
        })
        .catch((err) => {
          commit("setSummaryLoader", false);
          commit("setSummaryRequest", false);
          commit("setNotActiveAccount403", true);
          console.log(err);
          console.log(err, "toerror403");
          console.log(err.status, "toerror403");
          // console.log(err.response.status, "toerror403");
        });
    },
    summaryLoading({ commit }, val) {
      commit("setSummaryLoader", val);
    },
    summaryRequesting({ commit }, val) {
      commit("setSummaryLoader", val);
    },
    fetchTotalSessions({ commit, state }) {
      const opts = {
        params: {
          authentication_token: state.userData.jwt,
          location: state.userData.location,
        },
      };
      return axios
        .get(`${API_URL}/total`, opts)
        .then((response) => response.data)
        .then(({ data, personalBest }) =>
          commit("setTotalSessions", totalSessionParser(data[0], personalBest))
        )
        .catch((err) => {
          console.log(err);
        });
    },
    lastSessionLoading({ commit }) {
      commit("setLastSessionLoader", true);
    },
    registerUserData: function(_, data) {
      return axios
        .post(`${API_URL}/register`, data)
        .then((response) => response.data)
        .catch((err) => {
          console.error(err);
        });
    },
    requestLoader({ commit }, val) {
      commit("setLoader", val);
    },
    clearSignUpError({ commit }) {
      commit("setSignUpError", { code: "", message: "" });
    },
    clearLoginErrors({ commit }) {
      commit("setLoginError", { code: "", message: "" });
    },
    setCustomError({ commit }, err) {
      commit("setLoginErrorCustomMessage", err);
    },
    emailSent({ commit }, val) {
      commit("recoveryEmailSent", val);
    },
    getBrandingName({ commit }, state, val) {
      console.log("val");
      console.log(val);
      console.log(state);
      console.log("/val");
      return axios
        .post(`${API_URL}/branding`, {location: state}, { //PROD
        // .post('http://localhost:3200/nagizone/branding', {location: state}, { //DEV API
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "application/json"
          },
        })
        .then((response) => {
          commit("setCustomBranding", response.data);
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mutations: {
    setUsernameError(state, val) {
      console.log(val, "setUsernameError");
      state.usernameError = val;
    },
    setPasswordError(state, val) {
      state.passwordError = val;
    },
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    setUserData(state, val) {
      state.userData = val;
    },
    setLastSession(state, val) {
      state.lastSession = val;
    },
    setTotalSessions(state, val) {
      state.totalSessions = val;
      state.personalBest = val.personalBest;
    },
    setTotalSessionsCount(state, val) {
      state.totalSessionsCount = val;
    },
    setDisableSummaryButtons(state, val) {
      state.disableButton = val;
    },
    setSummary(state, val) {
      state.summary = val;
    },
    setSummaryLoader(state, val) {
      state.summaryLoader = val;
    },
    setLastSessionLoader(state, val) {
      state.lastSessionLoader = val;
    },
    setSummaryRequest(state, val) {
      state.summaryRequest = val;
    },
    setNotActiveAccount403(state, val) {
      state.notActiveAccount403 = val;
    },
    setLoader(state, val) {
      state.performingRequest = val;
    },
    setSignUpError(state, val) {
      state.signUpError = { code: val.code, message: val.message };
    },
    setLoginError(state, val) {
      state.loginError = {
        code: val.code,
        message: val.message.split(".")[0] || val.message,
      };
    },
    setLoginErrorCustomMessage(state, val) {
      state.loginError = {
        code: val.code,
        message: val.message,
      };
    },
    recoveryEmailSent(state, val) {
      state.recoveryEmailSent = val;
    },
    setCustomBrandingLS(state, val) {
      state.customBranding.theBranding = val;
    },
    setCustomBranding(state, val) {
      state.customBranding.theBranding = val;
      let brandingName = state.customBranding.theBranding;
      localStorage.setItem("brandingNameLS", brandingName);

      switch(brandingName) {
        case "ZONYX":
          state.customBranding.backgroundImage = "bg.jpg";
          state.customBranding.logo = "nagiZoneLogo.png";
          state.customBranding.bgColor = "#030d2c";
          state.customBranding.bgColorLogoFooter = "#030d2c";
          state.customBranding.privacyColor = "#6f8396";
          state.customBranding.style = "style1";
          break;
        case "SUMA":
          state.customBranding.backgroundImage = "bg.jpg";
          state.customBranding.logo = "custom_logo2.png";
          state.customBranding.bgColor = "#61A512";
          state.customBranding.bgColorLogoFooter = "#61A512";
          state.customBranding.privacyColor = "#fff";
          state.customBranding.style = "style2";
          break;
        case "BEONE":
          state.customBranding.backgroundImage = "bg3.jpg";
          state.customBranding.logo = "custom_logo3.png";
          state.customBranding.bgColor = "#002060";
          state.customBranding.bgColorLogoFooter = "#002060";
          state.customBranding.privacyColor = "#fff";
          state.customBranding.style = "style1";
          break;
        default:
          state.customBranding.backgroundImage = "bg.jpg";
          state.customBranding.logo = "nagiZoneLogo.png";
          state.customBranding.bgColor = "#030d2c";
          state.customBranding.bgColorLogoFooter = "#030d2c";
          state.customBranding.privacyColor = "#6f8396";
          state.customBranding.style = "style1";
      }
    },
    getBranding(state, val){
      store.dispatch("getBrandingName", val);      
    }
  },
});
