import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { shhh } from "./config";
import { store } from "./store.js";
import i18n from "./i18n";

const fb = require("./firebaseConfig.js");
const jsonwebtoken = require("jsonwebtoken");
import AsyncComputed from 'vue-async-computed'

Vue.use(AsyncComputed);

Vue.config.productionTip = false;

const uppercaseFilter = (text) => {
  if (!text) return "";
  const stringText = text.toString();
  return stringText.toUpperCase();
};
Vue.filter("uppercase", uppercaseFilter);

const capitalizeFilter = (text) => {
  if (!text) return "";
  const stringText = text.toString().toLowerCase();
  return stringText.replace(
    /(^|\. *)([a-z])/g,
    (_, separator, char) => separator + char.toUpperCase()
  );
};
Vue.filter("capitalize", capitalizeFilter);

//handle page reloading
let app;

fb.auth.onAuthStateChanged(async (user) => {
  if (user) {
    const userData = await fb.usersCollection
      .doc(user.uid)
      .get()
      .then((res) => res.data());
    const dataObj = {
      AuthToken: userData.UserId,
      //AuthTokentest: userData,
      UserId: userData.UserId,
      //location: userData.location,
      location: userData.InstallationId,
      now: new Date(),
      userData: userData,
    };
    const token = jsonwebtoken.sign(dataObj, shhh, { algorithm: "HS256" });
    store.commit("setUserData", {
      ...userData,
      jwt: token,
    });
    console.log(token, "Soy token onAuthStateChanged");
  }

  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      store,
      i18n,
      render: (h) => h(App),
    });
  }
});
